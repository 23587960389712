html, body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0d1424;
  height: 100%;
}

body {
  overflow-y: scroll;
}

h1 {
  font-size: 18px !important;
  margin-bottom: 0 !important;
}

.container {
  background: #FFF;
  height: 100%;
}

.mc-touch {
    padding-top: 20px;
}



.mc-center {
    text-align: center;
}
.mc-sensors {
    padding-top: 40px;
}
.mc-sensors .row {
    align-items: stretch;
    margin-bottom: 40px;
}

.mc-sensors .row .card {
    height: 100%;
}
