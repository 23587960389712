.mc-sensors {
    padding-top: 40px;
}
.mc-sensors .row {
    align-items: stretch;
    margin-bottom: 40px;
}

.mc-sensors .row .card {
    height: 100%;
}